.school>img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    background: rgb(0, 0, 0, 10%);
}

.school h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    padding: 0 1rem 12px;
}

.schoolInfo {
    gap: 12px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 1rem 12px;
    justify-content: space-between;
}

.schoolInfo p {
    flex-basis: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.schoolInfo p,
.schoolInfo span {
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
}

.ellipse {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 15px;
    right: 15px;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    border-radius: 100px;
    background: var(--white);
}

.popover {
    top: 60px;
    right: 15px;
    position: absolute;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
    .school>img {
        height: 250px;
    }

    .school h4 {
        font-size: 16px;
        line-height: 26px;
        padding: 0 1rem 1rem;
    }

    .schoolInfo p,
    .schoolInfo span {
        font-size: 12px;
        line-height: 20px;
    }
}