.container {
    padding: 32px;
    background: var(--white);
}

@media screen and (max-width: 1023px) {
    .container {
        padding: 0;
        background: var(--white);
    }
}