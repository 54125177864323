.container {
  display: flex;
  padding: 2rem 1rem;
  margin: 2rem 1rem;
  border-radius: 10px;
  align-items: center;
  background: var(--white);
  justify-content: center;
  flex-direction: column;
}

.container>img {
  width: 280px;
  height: 280px;
  object-fit: cover;
}

.container>h4 {
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin-top: 24px;
}

.container>p {
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 1rem;
  max-width: 450px;
}

.container>button {
  width: 195px;
  margin: 24px;
}

@media screen and (max-width: 1023px) {
  .container {
    height: auto;
    width: 100%;
    margin: 0;
    padding: 1rem;
    min-height: 90vh;
  }

  .container>img {
    width: 240px;
    height: 240px;
  }

  .container>h4 {
    font-size: 18px;
    line-height: 30px;
  }

  .container>p {
    font-size: 14px;
    line-height: 23px;
  }
}