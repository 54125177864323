.containerPadding {
    padding: 40px 32px;
}

.container {
    gap: 33px;
    display: flex;
    flex-wrap: wrap;
    color: var(--grey);
    align-items: center;
}

.container>div {
    gap: 1.5rem;
    width: 360px;
    height: 460px;
    display: flex;
    overflow: hidden;
    position: relative;
    border-radius: 20px;
    flex-direction: column;
    background: var(--white);
}

.container .emptyTab {
    padding: 1rem;
    justify-content: center;
}

.container .emptyTab img {
    width: 80px;
    height: 80px;
    cursor: pointer;
    object-fit: cover;
}

.container .emptyTab h2 {
    font-size: 31px;
    font-weight: 500;
    line-height: 41px;
}

.container .emptyTab p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}

.container .school>img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    background: rgb(0, 0, 0, 10%);
}

.container .school h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    padding: 0 1rem 12px;
}

.container .schoolInfo {
    gap: 12px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 1rem 12px;
    justify-content: space-between;
}

.container .schoolInfo p {
    flex-basis: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.container .schoolInfo p,
.container .schoolInfo span {
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
}

.ellipse {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 15px;
    right: 15px;
    padding: 10px;
    position: absolute;
    border-radius: 100px;
    background: var(--white);
}

.active {
    color: #63AD0E;
}

.inactive {
    color: #C7920B;
}

@media screen and (max-width: 768px) {
    .containerPadding {
        padding: 16px;
    }

    .container {
        background: var(--white);
    }

    .container>div {
        width: 343px;
        height: 415px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    }

    .container .emptyTab img {
        width: 60px;
        height: 60px;
    }

    .container .emptyTab h2 {
        font-size: 20px;
        line-height: 30px;
    }

    .container .emptyTab p {
        font-size: 14px;
        line-height: 22px;
    }

    .container .school>img {
        height: 250px;
    }

    .container .school h4 {
        font-size: 16px;
        line-height: 26px;
        padding: 0 1rem 1rem;
    }

    .container .schoolInfo p,
    .container .schoolInfo span {
        font-size: 12px;
        line-height: 20px;
    }
}