.container {
    width: 90%;
    text-align: center;
    margin: 2rem auto;

    gap: 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.container img {
    width: 280px;
    height: 280px;
    object-fit: cover;
}

.container h4 {
    font-size: 25px;
    font-weight: 500;
    line-height: 35px;
    color: var(--grey);
}

.container p {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: var(--grey3);
}

.buttonContainer {
    gap: 1.5rem;
    display: flex;
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .container {
        gap: 1rem;
        margin: 1rem auto;
    }

    .container img {
        width: 200px;
        height: 200px;
    }

    .container h4 {
        font-size: 20px;
        line-height: 30px;
    }

    .container p {
        font-size: 14px;
        line-height: 22px;
    }

    .buttonContainer {
        gap: 1rem;
        width: 100%;
        flex-wrap: wrap;
    }
}