.quillContainer {
  margin-bottom: 24px;
  box-sizing: border-box;
}

.quill {
  border: none;
}

.ql-container.ql-snow {
  border: 1px solid #2e2e2e !important;
}

.ql-toolbar.ql-snow {
  border-radius: 5px;
  padding: 0px;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.ql-editor {
  display: block;
  width: 100%;
  padding: 18px 15px;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  border-radius: 5px;
  animation: appear 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid #2e2e2e !important;
}

.quillContainer > label {
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 5px;
  display: block;
}

.tip {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 5px;
}
