.container svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    object-fit: cover;
}

.container button {
    width: fit-content;
    padding: 10px 30px;
}

.body {
    gap: 1rem;
    display: flex;
    padding: 24px 32px;
    align-items: flex-start;
}

.leftSide {
    top: 10px;
    width: 100%;
    height: 587px;
    max-width: 452px;
    min-width: 352px;
    position: sticky;
    background: var(--white);
}

.leftSide .top {
    padding: 24px;
    background: var(--purple);
}

.leftSide .top>h4 {
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    font-style: normal;
    color: var(--white);
    font-family: Archivo;

    margin-bottom: 1.5rem;
}

.leftSide .bottom {
    padding: 24px;
    background: var(--white);

    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.moduleTitle {
    gap: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.moduleTitle h4 {
    flex: 1;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: var(--grey);
    font-style: normal;
    font-family: Archivo;
}

.week {
    gap: 1rem;
    display: flex;
    padding: 5px 0;
    font-size: 13px;
    cursor: pointer;
    font-weight: 400;
    line-height: 20px;
    font-style: normal;
    color: var(--grey);
    font-family: Archivo;
    margin-bottom: 0.5rem;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: row-reverse;
}

.courseFile {
    gap: 5px;
    display: flex;
    flex-direction: column;
}

.courseFile>:nth-child(1) {
    font-size: 16px;
    font-weight: 500;
    color: var(--grey);
    font-style: normal;
    font-family: Archivo;
}

.courseFile>:nth-child(2) {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    font-style: normal;
    color: var(--grey3);
    font-family: Archivo;

    gap: 3px;
    display: flex;
    align-items: center;
}

.rightSide {
    padding: 24px 16px;
    background: var(--white);
    gap: 2rem;
    display: flex;
    flex-direction: column;
}

.rightSide p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: var(--grey);
    font-style: normal;
    font-family: Archivo;
}

.rightSide .top {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--veryLightPurple);
}

.rightSide .top>h3 {
    font-size: 31px;
    font-weight: 500;
    line-height: 41px;
    font-style: normal;
    color: var(--grey);
    font-family: Archivo;
}

.rightSide .top>p {
    padding-block: 5px;
    gap: 10px;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
}

.rightSide .bottom>div {
    gap: 1.5rem;
    display: flex;
    padding-top: 24px;
    flex-direction: column;
}

.rightSide .bottom .info h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    font-style: normal;
    color: var(--grey);
    font-family: Archivo;

}

.footer {
    display: grid;
    gap: 2.5rem;
}

.footer button:first-child {
    justify-self: flex-end;
}

.actionButton,
.buttonContainer {
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.actionButton {
    justify-content: flex-end;
}


@media screen and (min-width: 778px) and (max-width: 1024px) {
    .leftSide {
        display: none;
        position: relative;
    }
}

@media screen and (max-width: 778px) {
    .container svg {
        width: 16px;
        height: 16px;
    }

    .breadCrumbs {
        display: none;
    }

    .body {
        gap: 2rem;
        padding: 2rem 0;
        flex-direction: column;
        background: var(--white);
    }

    .leftSide {
        display: none;
        position: relative;
    }

    .footer {
        gap: 1rem;
    }

    .footer button:first-child {
        display: none;
    }

    .footer button:last-child {
        padding: 10px;
    }
}