.container {
  background-color: var(--purple);
}

.container > h4 {
  padding: 40px 32px 12px 32px;
  font-family: Archivo;
  font-size: 25px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--white);
}

.container > div:nth-child(2) {
  padding: 1rem 2rem;
}

.container > div:nth-child(2) > div {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding: 1rem 12px;
  font-family: Archivo;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: var(--white);
}

.container
  > div:nth-child(2)
  > div:not(.container > div:nth-child(2) > div:last-child) {
  border-bottom: 1px solid #ffffff;
}

.container > div:nth-child(3) {
  padding: 1rem 2rem;
  border-top: 1px solid #ffffff;
}
