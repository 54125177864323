.container {
  margin: 24px;
  color: var(--grey);
}

.container > h1 {
  font-family: Archivo;
  font-size: 39px;
  font-weight: 500;
  line-height: 50px;
  text-align: left;
}

.container > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  margin-top: 0.5rem;
}
