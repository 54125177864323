.container {
  gap: 2rem;
  display: flex;
  flex-direction: column;
}

.container>div:first-child,
.container>section:first-child {
  padding-bottom: 2rem;
}

@media screen and (max-width: 1023px) {

  .container>div:not(:last-child),
  .container>section:not(:last-child) {
    border-bottom: 1px solid var(--sectionBorder);
  }
}