.container {
    gap: 2rem;
    display: flex;
    padding: 24px;
    flex-direction: column;

    font-style: normal;
    font-family: Archivo;
}

.container button {
    width: fit-content;
    padding: 10px 30px;
}

.footer {
    gap: 1rem;
    flex-wrap: wrap;
    padding: 16px 32px;
    border-radius: 10px;
    display: inline-flex;
    background: var(--white);
}

.footer p,
.footer a {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    font-style: normal;
}

.footer a {
    color: var(--purple);
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

@media screen and (max-width: 1023px) {
    .container {
        padding: 16px;
        background: var(--white);
    }

    .footer {
        gap: 5px;
        padding: 16px;
    }

    .footer p,
    .footer a {
        font-size: 14px;
        line-height: 24px;
    }
}