.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  background-color: var(--white);
}

.container > img {
  width: 240px;
  height: 240px;
}

.container > h4 {
  font-family: Archivo;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: var(--grey);
}

.container > p {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: var(--grey3);
  width: 50%;
}

.buttonSection {
  display: flex;
  align-items: center;
  gap: 24px;
  margin: 24px;
}

.buttonSection > button {
  max-width: 158px;
}

.buttonSection > span {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: var(--timerGreen);
  cursor: pointer;
}
