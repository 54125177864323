.container button {
    width: auto;
    padding: 10px 30px;
}

.header {
    gap: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 24px 48px;
    background: var(--white);
    border-top: 1px solid var(--veryLightPurple);
}

.header>p {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    font-style: normal;
    color: var(--grey);
    font-family: Archivo;
    user-select: none;
}

.body {
    gap: 40px;
    padding: 80px;
    display: flex;
    margin-top: 24px;
    flex-direction: column;
    background: var(--white);
}

.intro {
    gap: 40px;
    display: flex;
    align-items: center;
}

.intro img {
    width: 660px;
    height: 480px;
    object-fit: cover;
    border-radius: 10px;
}

.intro>div p,
.intro>div span {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--purple);
}

.intro>div span::after {
    content: ",";
    padding-right: 1.5px;
}

.intro>div span:nth-child(3)::after {
    content: "";
    padding-right: 0;
}

.intro>div h1 {
    font-size: 39px;
    font-weight: 500;
    line-height: 50px;
    margin-block: 16px;
}

.intro>div p {
    color: var(--grey3);
}

.content,
.content ol {
    gap: 24px;
    display: flex;
    flex-direction: column;
}

.content p,
.content li {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
}

.content ol {
    padding-left: 16px;
}

.content img {
    width: 100%;
    height: 440px;
    object-fit: cover;
    margin-block: 16px;
    border-radius: 10px;
}

@media screen and (max-width: 1124px) {
    .container button {
        padding: 10px 20px;
    }

    .header {
        gap: 10px;
    }

    .header {
        padding: 16px;
        border-top: none;
        border-bottom: 1px solid var(--veryLightPurple);
    }

    .header>p {
        font-size: 14px;
        line-height: 22px;
    }

    .body {
        gap: 1rem;
        margin-top: 0;
        padding: 40px;
        padding-block: 24px;
    }

    .intro {
        gap: 40px;
        flex-wrap: wrap-reverse;
    }

    .intro img,
    .content img {
        width: 100%;
        height: 360px;
    }

    .intro>div h1 {
        font-size: 31px;
        line-height: 40px;
        margin-block: 10px;
    }
}

@media screen and (max-width: 767px) {
    .body {
        padding-inline: 16px;
    }

    .header>p {
        font-size: 12px;
        line-height: 18px;
    }

    .intro>div p,
    .intro>div span {
        font-size: 12px;
        line-height: 18px;
    }

    .intro>div h1 {
        font-size: 25px;
        line-height: 35px;
        margin-block: 8px;
    }

    .content p,
    .content li {
        font-size: 14px;
        line-height: 22px;
    }
}