.helloUser {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  padding: 24px 32px;
  border-radius: 10px;
  background: var(--white);
}

.helloUser h2 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 39px;
  font-style: normal;
  font-weight: 500;
  line-height: 51px;
}

.helloUser p {
  margin: 1rem 0;
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  width: 50%;
  min-width: 100%;
}

@media screen and (max-width: 1023px) {
  .helloUser {
    padding: 24px 8px;
  }

  .helloUser h2 {
    font-size: 25px;
    line-height: 27px;
  }

  .helloUser p {
    font-size: 14px;
    line-height: 23px;
    width: 100%;
  }
}