.container {
    gap: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    padding: 1.5rem;
    width: fit-content;
    border-radius: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background: var(--white);
}

.container span {
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    line-height: 26px;
    color: var(--grey);
    font-style: normal;
    white-space: nowrap;
    font-family: Archivo;
}

.container div {
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.container div:last-child {
    width: 100%;
    padding-top: 24px;
    border-top: 1px solid var(--sectionBorder);
}

.container div:last-child .delete {
    color: var(--red);
}