.container {
    gap: 24px;
    width: 90%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    padding: 2rem auto;
    flex-direction: column;
    justify-content: center;
}

.container button {
    width: auto;
}

.header h3 {
    font-size: 25px;
    font-weight: 500;
    line-height: 36px;
}

.header p {
    font-size: 16px;
    font-weight: 400;
    flex-basis: 100%;
    line-height: 25px;
    color: var(--grey3);
}

.header svg {
    cursor: pointer;
    margin-left: 1rem;
}

.header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.body {
    gap: 40px;
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.card {
    gap: 16px;
    display: flex;
    padding: 24px;
    max-width: 330px;
    text-align: center;
    border-radius: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 2px solid var(--greyWhite);
    box-shadow: 0 0 10px var(--sectionBorder);
}

.card>h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
}

.card>p {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--grey3);
}

.card>h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: var(--grey);
}

.copyCode {
    width: 100%;
    display: flex;
    align-items: center;
}

.code {
    padding: 8px;
    overflow: auto;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    border-radius: 5px;
    background: var(--veryLightPurple);
}

@media screen and (max-width: 1023px) {
    .container {
        gap: 24px;
        height: unset;
        margin: 2rem auto;
    }

    .header h3 {
        font-size: 20px;
        line-height: 30px;
    }

    .header p {
        font-size: 14px;
        line-height: 21px;
    }

    .copyCode {
        flex-wrap: wrap;
    }

    .buttonContainer {
        width: 100%;
        display: flex;
        margin-top: 16px;
        align-items: center;
        justify-content: flex-end;
    }

    .buttonContainer button {
        padding: 0;
        width: auto;
    }

    .body {
        gap: 24px;
        flex-wrap: wrap;
    }

    .card {
        gap: 8px;
        padding: 16px;
        max-width: 350px;
        box-shadow: none;
        border: 2px solid var(--greyWhite);
    }

    .card>img {
        height: 130px;
        object-fit: cover;
    }

    .card>h4 {
        font-size: 14px;
        line-height: 22px;
    }

    .card>p {
        font-size: 12px;
        line-height: 18px;
    }

    .card>h4 {
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        color: var(--grey);
    }
}

@media screen and (max-width: 768px) {
    .container {
        gap: 1.5rem;
        margin: 1rem auto;
    }

    .header h3 {
        font-size: 18px;
        line-height: 28px;
    }

    .header p {
        font-size: 13px;
        line-height: 21px;
        padding-right: 1rem;
    }
}