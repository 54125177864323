.container {
    gap: 1.5rem;
    display: flex;
    flex-direction: column;

    padding: 1.5rem;
    border-radius: 10px;
    background: var(--veryVeryLightPurple);
}

.container span {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--grey3);
}

.container h4 {
    font-size: 16px;
    margin-top: 10px;
    font-weight: 500;
    line-height: 26px;
    color: var(--grey);
}

.container>div {
    gap: 2rem;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1.5rem;
    justify-content: space-between;
    border-bottom: 1px solid var(--sectionBorder);
}

.container>div:last-child {
    padding-bottom: 0;
    border-bottom: none;
}