.body {
    gap: 1.5rem;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin: 1.5rem;
    padding: 2rem;
    background: var(--white);
}

.body>section {
    border-radius: 0;
    border-bottom: 1px solid var(--sectionBorder);
}

.body>section:last-child {
    border-bottom: none;
}

@media screen and (max-width: 1023px) {
    .body {
        margin: 0;
        padding: 1rem 1rem 0;
    }
}