.container,
.textSection,
.textSection > div {
  gap: 1.5rem;
  display: flex;
  color: var(--grey);
  flex-direction: column;
}

.container h2 {
  font-size: 31px;
  font-weight: 500;
  line-height: 49px;
  color: var(--grey);
  margin-bottom: 16px;
}

.container button {
  width: fit-content;
  padding: 10px 30px;
}

.container .buttonSection {
  gap: 1.5rem;
  display: flex;
  margin-top: 40px;
  padding-top: 24px;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid var(--sectionBorder);
}

.buttonSectionThree .canelButton {
  padding: 0;
  margin-right: auto;
}

.flexButtonSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexButtonSection button {
  padding: 0;
}

.fileUploadInfo {
  padding: 8px;
  margin-top: -1.125rem;
  background: var(--progressBarYellow);
}

.textSection span,
.fileUploadInfo li {
  font-size: 13px;
  list-style: none;
  font-weight: 400;
  line-height: 20px;
  color: var(--grey);
}

.textSection span {
  color: var(--grey3);
}

.textSection .listUl {
  padding: 0;
}

.textSection p,
.textSection li {
  font-size: 16px;
  list-style: none;
  font-weight: 400;
  line-height: 26px;
  color: var(--grey);
}

.textSection img {
  width: 500px;
  height: 500px;
  max-width: 100%;
  object-fit: cover;
}

.textSection > div {
  gap: 8px;
}

.schoolImportanceLabel {
  font-size: 18px;
  font-weight: 400;
  line-height: 33px;
  color: var(--grey);
}

.date {
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
  justify-content: flex-start;
}

.date label {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  font-style: normal;
  color: var(--grey);
  font-family: Archivo;
}

.date input {
  width: 100%;
  height: 60px;
  outline: none;
  padding: 0 12px;
  user-select: none;
  position: relative;
  border-radius: 5px;
  font-family: Archivo;
  box-sizing: border-box;
  -webkit-user-select: none;
  border: 1px solid var(--grey3);
}

.date input[type="date"]:before {
  color: var(--grey3);
  font-family: Archivo;
  content: attr(placeholder);
}

.date input[type="date"]:focus,
.date input[type="date"]:valid {
  color: var(--grey3);
  font-family: Archivo;
}

.date input[type="date"]:focus:before,
.date input[type="date"]:valid:before {
  content: "" !important;
  font-family: Archivo;
}

.date input[type="date"]::-webkit-calendar-picker-indicator {
  background: none;
}

.selectDate {
  position: relative;
}

.date img {
  top: 20px;
  right: 2%;
  width: 18px;
  height: 18px;
  position: absolute;
  pointer-events: none;
}

.cohort h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 29px;
  margin-bottom: 16px;
  padding-bottom: 4px;
  border-bottom: 1px solid var(--sectionBorder);
}

.cohort > div {
  gap: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.objectives > ul {
  list-style-type: disc;
}

.objectives > ul > li {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  color: var(--grey);
  font-family: Archivo;
  margin-bottom: 0.2rem;
}

@media screen and (max-width: 768px) {
  .container h2 {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 10px;
  }

  .schoolImportanceLabel {
    font-size: 16px;
    line-height: 26px;
  }

  .textSection p,
  .textSection li {
    font-size: 14px;
    line-height: 22px;
  }

  .textSection img {
    width: 230px;
    height: 250px;
  }

  .container .buttonSection {
    gap: 1rem;
    justify-content: space-between;
  }

  .buttonSectionThree {
    justify-content: center;
    flex-wrap: wrap-reverse;
  }

  .buttonSectionThree .canelButton {
    flex-basis: 100%;
    margin-right: 0;
  }

  .date {
    margin-bottom: 25px;
  }

  .date label {
    font-size: 14px;
    line-height: 23px;
  }

  .date input {
    height: 50px;
  }

  .date img {
    top: 15px;
    right: 3%;
  }
}
