.container {
    gap: 2rem;
    display: flex;
    padding: 24px;
    flex-direction: column;

    font-style: normal;
    font-family: Archivo;
}

.subContainer {
    gap: 24px;
    display: flex;
    flex-direction: column;

    padding: 24px 32px;
    background: var(--white);
}

.inputSection {
    width: 100%;
    max-width: 650px;
    position: relative;
}

.inputSection>input {
    width: 100%;
    padding: 12px 20px 12px 64px;
    border-radius: 10px;
    border: 1px solid var(--foundation-grey-g-50, #d8d8d8);
    background: var(--white-body-text, #fff);
    color: var(--foundation-grey-g-900, #2e2e2e);
    outline: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
}

.inputSection>svg {
    position: absolute;
    left: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
}

.tableHeader,
.tableBody {
    gap: 20px;
    display: grid;
    grid-template-columns: 70px minmax(100px, 200px) 4fr 1fr auto 0;
    align-items: center;
    justify-content: space-between;

    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: var(--grey);
}

.tableHeader {
    font-weight: 500;
    padding-block: 10px;
    border-bottom: 1px solid var(--grey);
}

.tableHeader>span:last-child {
    opacity: 0;
}

.tableBody {
    position: relative;
    padding: 40px 0 15px;
    border-bottom: 1px solid var(--sectionBorder);
}

.tableBody>span:nth-child(3) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.tableBody:last-child {
    border-bottom: none;
    padding-bottom: 40px;
}

.tableBody>span a:last-child {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

.options button,
.status mark {
    cursor: pointer;
    font-size: 13px;
    font-weight: 400;
    padding: 8px 16px;
    border-radius: 4px;
    color: var(--white);
    text-transform: capitalize;
}

.options button:nth-child(1),
.open mark {
    background: #C7920B;
}

.options button:nth-child(2),
.resolved mark {
    background: #4A820B;
}

.options button:nth-child(3),
.closed mark {
    color: var(--grey);
    background: #B9B9B9;
}

.modalContainer {
    top: 20%;
    z-index: 2;
    opacity: 1;
    right: 50px;
    padding: 8px 24px;
    position: absolute;
    border-radius: 10px;
    background: var(--white);
    transition: all 0.3s ease-out;
    border: 1px solid rgb(191, 191, 191);
    box-shadow: 0 0 5px rgba(100, 100, 100, 0.125);
}

.modalContainer>h4 {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--GREY3);
}

.options {
    gap: 8px;
    display: flex;
    flex-direction: column;
}

.options button {
    border: none;
    outline: none;
}

.noResult {
    font-size: 18px;
    line-height: 29px;
    margin: 2rem auto;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
    .container {
        gap: 0;
        padding: 0;
        background: var(--white);
    }

    .subContainer {
        padding: 1rem;
    }
}

@media screen and (max-width: 767px) {
    .container {
        gap: 0;
        padding: 0;
        background: var(--white);
    }

    .subContainer {
        padding: 1rem;
    }

    .tableHeader span:nth-child(2),
    .tableHeader span:nth-child(3),
    .tableBody span:nth-child(2),
    .tableBody span:nth-child(3) {
        display: none;
    }

    .tableHeader,
    .tableBody {
        font-size: 14px;
        line-height: 22px;
        grid-template-columns: minmax(50px, 100px) 1fr auto;
    }

    .status mark {
        font-size: 12px;
        padding: 6px 14px;
    }

    .inputSection>input {
        font-size: 14px;
        line-height: 22px;
        border-radius: 5px;
        padding: 8px 20px 8px 44px;
    }

    .inputSection>svg {
        left: 16px;
        width: 18px;
        height: 18px;
    }
}