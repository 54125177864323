.container {
    display: flex;
    padding: 24px;
    border-radius: 10px;
    flex-direction: column;
    background: var(--white);

    font-style: normal;
    font-family: Archivo;
}

.tableHeader,
.tableBody {
    gap: 20px;
    display: grid;
    grid-template-columns: 150px 2fr 3fr 70px;
    align-items: center;
    justify-content: space-between;

    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: var(--grey);
}

.tableHeader {
    font-weight: 500;
    padding-block: 10px;
    border-bottom: 1px solid var(--grey);
}

.tableBody {
    padding: 40px 0 15px;
    border-bottom: 1px solid var(--sectionBorder);
}

.tableBody:last-child {
    border-bottom: none;
    padding-bottom: 40px;
}

.tableBody span p:nth-child(2) {
    display: none;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
    .container {
        padding: 0;
    }
}

@media screen and (max-width: 767px) {
    .container {
        padding: 0;
    }

    .tableHeader span:nth-child(2),
    .tableBody span:nth-child(2) {
        display: none;
    }

    .tableHeader,
    .tableBody {
        font-size: 14px;
        line-height: 22px;
        grid-template-columns: 1fr 3fr;
    }

    .tableBody span p:nth-child(2) {
        display: block;
        font-size: 12px;
        color: var(--grey3);
    }
}