.container {
  background-color: var(--white);
  margin: 24px;
}

.header {
  display: flex;
  align-items: center;
  gap: 40px;
}

.header > h4 {
  font-family: Archivo;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #2e2e2e;
}

.header > div {
  min-width: 217px;
}

.analyticsSection {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  justify-content: space-between;
  margin-top: 1rem;
  flex-wrap: wrap;
}

.analyticsSection > div {
  border: 1px solid #dcd7fe;
  text-align: center;
  min-height: 139px;
  padding: 24px;
  border-radius: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.analyticsSection > div > h4 {
  font-family: Archivo;
  font-size: 39px;
  font-weight: 600;
  line-height: 50px;
  color: #000000;
  margin-bottom: 1rem;
}

.analyticsSection > div > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: #737373;
}
