.container {
    gap: 2rem;
    display: flex;
    padding: 24px;
    flex-direction: column;

    font-style: normal;
    font-family: Archivo;
}

.container button {
    width: fit-content;
    padding: 10px 30px;
}

.footer {
    gap: 1rem;
    flex-wrap: wrap;
    padding: 16px 32px;
    border-radius: 10px;
    display: inline-flex;
    background: var(--white);
}

.footer p,
.footer a {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    font-style: normal;
}

.footer a {
    color: var(--purple);
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

.emptyTab {
    gap: 10px;
    width: 100%;
    height: 417px;
    display: flex;
    padding: 40px 16px;
    text-align: center;
    font-style: normal;
    border-radius: 10px;
    align-items: center;
    font-family: Archivo;
    flex-direction: column;
    justify-content: center;
    background: var(--white);
}

.emptyTab>h2 {
    font-size: 31px;
    font-weight: 500;
    line-height: 40px;
}

.emptyTab>p {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    max-width: 600px;
}

.emptyTab>button {
    margin-top: 22px;
    width: fit-content;
}

@media screen and (max-width: 1023px) {
    .container {
        padding: 40px 16px 16px;
        background: var(--white);
    }

    .footer {
        gap: 5px;
        padding: 16px;
    }

    .footer p,
    .footer a {
        font-size: 14px;
        line-height: 24px;
    }

    .emptyTab {
        height: 317px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
    }

    .emptyTab>h2 {
        font-size: 20px;
        line-height: 30px;
    }

    .emptyTab>p {
        font-size: 14px;
        line-height: 22px;
    }
}