.container {
    gap: 2rem;
    display: flex;
    padding: 2rem 24px;
    flex-direction: column;
}

.scrollContainer {
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}


.scrollContainer::-webkit-scrollbar {
    display: none;
}

.insight {
    gap: 1.5rem;
    overflow-x: auto;
    align-items: center;
    display: inline-flex;
}

.insight div {
    gap: 10px;
    display: grid;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 268px;
    height: 184px;
    padding: 1.5rem;
    border-radius: 10px;
    background: var(--white);
    box-shadow: 0 0 3px var(--sectionBorder);
}

.insight p {
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    color: var(--grey);
}

.insight h2 {
    font-size: 39px;
    font-weight: 500;
    line-height: 50px;
    color: var(--grey);
}

.insight span {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--grey3);
}

@media screen and (max-width: 1023px) {
    .container {
        gap: 1.5rem;
        padding: 1rem;
    }


    .insight div {
        width: 282px;
        height: 147px;
        box-shadow: none;
    }

    .insight p {
        font-size: 18px;
        line-height: 25px;
    }

    .insight h2 {
        font-size: 31px;
        line-height: 41px;
    }

    .insight span {
        grid-row: 2;
        font-size: 10px;
        line-height: 16px;
    }
}