.Container {
  gap: 2rem;
  display: flex;
  padding: 2rem 1.5rem;
  flex-direction: column;
}

.schoolList {
  gap: 33px;
  display: flex;
  flex-wrap: wrap;
  color: var(--grey);
  align-items: center;
}

.schoolList > div {
  gap: 1.5rem;
  width: 364px;
  height: 448px;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  flex-direction: column;
  background: var(--white);
}

.modalContainer {
  gap: 2rem;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.modalHeader {
  top: 0;
  gap: 2rem;
  display: flex;
  position: sticky;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  background: var(--white);
  justify-content: space-between;
  border-bottom: 1px solid var(--sectionBorder);
}

.modalHeader > h3 {
  font-size: 25px;
  font-weight: 500;
  line-height: 36px;
}

.modalHeader > svg {
  cursor: pointer;
}

.modalContent {
  gap: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.modalContent > div {
  max-width: 244px;
  flex-basis: 30%;
  height: 273px;
  border-radius: 10px;
  border: 1px solid var(--sectionBorder);
  gap: 1.5rem;
  padding: 8px;
  display: flex;
  cursor: pointer;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.modalContent > div img {
  width: 100%;
  height: 170px;
  object-fit: cover;
}

.modalContent > div h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}

.buttonContainer {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttonContainer > button {
  max-width: 250px;
}

@media screen and (max-width: 1023px) {
  .Container {
    gap: 1.5rem;
    padding: 1.5rem 1rem;
    background: var(--white);
  }

  .modalContent > div {
    width: 224px;
    height: 233px;
  }

  .modalContent > div img {
    width: 220px;
    height: 150px;
  }

  .modalHeader > h3 {
    font-size: 20px;
    line-height: 32px;
  }

  .modalContent > div h4 {
    font-size: 16px;
    line-height: 26px;
  }
}
