.container {
  padding: 40px 2rem;
  border-radius: 10px;
  background: var(--white);
}

.header {
  width: 100%;
  display: flex;
  font-style: normal;
  margin-bottom: 30px;
  padding-bottom: 16px;
  font-family: Archivo;
  flex-direction: column;
  border-bottom: 1px solid #d8d8d8;
}

.header > div {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  justify-content: space-between;
}

.header > div h2 {
  font-size: 31px;
  font-weight: 500;
  line-height: 41px;
  color: var(--grey);
}

.header > svg {
  height: 35px;
  cursor: pointer;
}

.role {
  gap: 7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.role p,
.role button {
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  line-height: 26px;
  color: var(--grey3);
  font-family: Archivo;
}

.role button {
  padding: 4px 8px;
  border-radius: 2px;
  color: var(--grey);
  background: var(--greyWhite);
  border: 1px solid var(--purple);
}

.listContainer > p,
.numberList li {
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
  color: var(--grey);
  font-style: normal;
  font-family: Archivo;
}

.listContainer p,
.listContainer h4 {
  margin-bottom: 2px;
  color: var(--grey3);
}

.numberList {
  list-style: decimal;
  padding-left: 20px;
}

.discList {
  list-style: disc;
  padding-left: 20px;
}

.duties {
  margin-bottom: 24px;
}

.footer {
  gap: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: calc(40px - 1rem);
}

.footer > button {
  width: fit-content;
  padding: 10px 30px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
  }

  .header {
    margin-bottom: 15px;
    padding-bottom: 5px;
  }

  .header > div h2 {
    font-size: 28px;
    line-height: 37px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
  }

  .header {
    margin-bottom: 15px;
    padding-bottom: 5px;
  }

  .header > div h2 {
    font-size: 18px;
    line-height: 27px;
  }

  .header > svg {
    height: 24px;
  }

  .role p,
  .role button,
  .listContainer > p,
  .numberList li {
    font-size: 12px;
  }
}
