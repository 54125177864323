.sectionsNavSection {
    margin: 1.5rem;
    padding: 1.5rem 2rem;
    border-radius: 10px;
    background: var(--white);
}

@media screen and (max-width: 1023px) {
    .container {
        background: var(--white);
    }

    .sectionsNavSection {
        margin: 0 0 1rem;
        padding: 1rem;
    }
}