.container {
    padding: 40px 32px;
    border-radius: 10px;
    margin-inline: 2rem;
    background: var(--white);
}

.body {
    flex: 1;
    width: 100%;
    gap: 1.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: stretch;
}

.body>span {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: var(--black);
}

.container ol {
    gap: 40px;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.listItem {
    flex: 1;
    gap: 24px;
    padding: 19px;
    display: flex;
    list-style: none;
    border-radius: 10px;
    align-items: center;
    border: 1px solid var(--sectionBorder);
}

.listItem img {
    width: 100%;
    height: 142px;
    max-width: 343px;
    object-fit: cover;
    border-radius: 8px;
}

.content {
    width: 100%;
    gap: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.title {
    gap: 1.5rem;
    display: flex;
    align-items: center;
}

.enrolledStatus span,
.title>h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: var(--grey);
    text-transform: capitalize;
}

.enrolledStatus span {
    color: var(--grey3);
}

.title>span {
    padding: 5px 20px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--grey);
    border-radius: 50px;
}

.paid {
    background: var(--paidIndicatorGreen);
}

.free {
    background: var(--progressBarIndicatorYellow);
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .container {
        padding: 40px 0;
        margin-inline: 1rem;
    }

    .body>span {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .container {
        padding: 40px 0;
        margin-inline: 1rem;
    }

    .body>span {
        display: none;
    }

    .listItem {
        flex: 1;
        gap: 24px;
        padding: 16px;
        align-items: flex-start;
        flex-direction: column;
    }

    .listItem img {
        width: 100%;
        min-height: 192px;
        max-width: 100%;
        object-fit: cover;
        border-radius: 8px;
    }

    .title>h4 {
        font-size: 14px;
        line-height: 24px;
    }

    .enrolledStatus span,
    .title>span {
        font-size: 12px;
        line-height: 18px;
    }
}