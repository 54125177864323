.container {
  height: 100%;
  overflow-y: auto;
  padding: 28px;
}

.closeSideNavv {
  text-align: right;
}

.userSection {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 21px;
}

.userSection>img {
  width: 51px;
  height: 51px;
  flex-shrink: 0;
}

.userSection>div>p:nth-child(1) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.userSection>div>p:nth-child(2) {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.inputSection {
  width: 100%;
  position: relative;
  margin-top: 4rem;
}

.inputSection>input {
  width: 100%;
  padding: 8px 17px 8px 57px;
  border-radius: 10px;
  border: 1px solid var(--foundation-grey-g-50, #d8d8d8);
  background: var(--white-body-text, #fff);
  color: var(--foundation-grey-g-900, #2e2e2e);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  outline: none;
}

.inputSection>svg {
  position: absolute;
  left: 17px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.navSection {
  margin-top: 32px;
}

.navSection>a,
.navSection>div:not(.otherOptions) {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1.5rem;
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  flex-wrap: wrap;
}

.navSection>div:not(.otherOptions) {
  padding: 0 !important;
}

.navSection>div>svg:nth-child(3) {
  margin-left: auto;
}

.link {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  border-radius: 4px;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: var(--grey);
  text-decoration: none;
}

.otherOptions>a {
  text-decoration: none;
  padding: 10px 10px 10px 42px;
  border-radius: 4px;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: block;
  color: var(--grey);
  white-space: nowrap;
}

.otherOptions {
  flex-basis: 100%;
  overflow-y: hidden;
  transition: all .3s ease-in-out
}

.otherOptionsLinkActive {
  color: var(--purple) !important;
}

.active {
  background-color: var(--veryLightPurple);
  color: var(--grey);
}

.inActive {
  color: var(--grey);
  padding: 10px;
}

.link {
  text-decoration: none;
  border-radius: 4px;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
}

.link:nth-child(2) {
  flex: 1;
}

.moreMenuContentInActive {
  color: var(--grey);
  border-radius: 5px;
  width: 100%;
  gap: 10px;
  display: flex;
  padding: 10px;
}

.moreMenuContentActive {
  background-color: var(--veryLightPurple);
  color: var(--grey);
  border-radius: 5px;
  width: 100%;
  gap: 10px;
  padding: 10px;
  display: flex;
}

.moreMenuContentInActive a {
  color: var(--grey);
}

.moreMenuContentActive a {
  color: var(--grey);
}

.logout {
  margin-top: 3rem;
  margin-left: 8px;
}