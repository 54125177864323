.container {
    gap: 2rem;
    display: flex;
    flex-direction: column;
}

.sectionsNavSection {
    border-radius: 10px;
    padding: 1.5rem 2rem;
    background: var(--white);
}

@media screen and (max-width: 1023px) {
    .container {
        background: var(--white);
    }

    .sectionsNavSection {
        margin: 0 0 1rem;
        padding: 1rem;
    }
}