.container {
  padding: 2rem 1.5rem;
  margin: 24px;
  display: flex;
  align-items: stretch;
}

.container > section {
  border-radius: 10px;
}

.container > section:nth-child(1) {
  flex-basis: 45%;
  border-radius: 10px 0 0 10px;
}

.container > section:nth-child(2) {
  flex-basis: 55%;
  border-radius: 0px 10px 10px 0px;
}
