.container {
  padding: 2rem 1.5rem;
  background-color: var(--white);
  margin: 24px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  background: #ffffff;
  border-radius: 10px 10px 0 0;
  margin-bottom: 0.5rem;
}

.header > h4 {
  font-family: Archivo;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #2e2e2e;
}

.header > div {
  min-width: 206px;
}
