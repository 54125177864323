.container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 24px;
  background: var(--white);
  width: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  display: none;
}

.container>img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.link {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 24px;
  text-decoration: none;
  white-space: nowrap;
}

.link>svg {
  margin-left: 1rem;
}

.link:nth-child(2)>svg {
  display: none;
}

.active {
  stroke: var(--purple);
  color: var(--foundation-primary-p-700, #664efe);
}

@media screen and (min-width: 737px) and (max-width: 1023px) {
  .container>img {
    width: 24px;
    height: 24px;
  }

  .link {
    font-size: 14px;
    line-height: 23px;
    gap: 1rem;
  }

  .link>svg {
    margin-right: 0.5rem;
  }
}

@media screen and (max-width: 737px) {
  .container {
    padding: 16px;
  }

  .container>img {
    width: 24px;
    height: 24px;
  }

  .link {
    font-size: 14px;
    line-height: 23px;
    gap: 1rem;
  }

  .link>svg {
    margin-right: 0.5rem;
  }
}