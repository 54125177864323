.modalContainer,
.modalInnerContainer {
  width: 90%;
  height: 100%;
  display: flex;
  margin: 1rem auto;
  flex-direction: column;
  justify-content: center;
}

.modalContainer h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.modalContainer p {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}


.modalContainer button {
  width: fit-content;
  padding: 10px 30px;
}

.modalInnerContainer {
  gap: 1rem;
  align-items: center;
  text-align: center;
}

.buttonSection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5rem;
  margin-top: 40px;
  width: 100%;
}

.buttonLeft {
  padding: 0;
  margin-top: -5px;
  justify-content: flex-start;
}

@media screen and (max-width: 768px) {
  .modalContainer h4 {
    font-size: 18px;
    line-height: 26px;
  }

  .modalContainer p {
    font-size: 14px;
    line-height: 22px;
  }

  .buttonSection {
    gap: 1rem;
    margin-top: 20px;
  }

  .buttonSection>button {
    width: 100%;
  }
}