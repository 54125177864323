.container {
    gap: 16px;
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.container span {
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    line-height: 26px;
    color: var(--grey);
    font-style: normal;
    white-space: nowrap;
    font-family: Archivo;
}

@media screen and (max-width: 768px) {
    .container span:nth-child(2) {
        display: flex;
    }
}