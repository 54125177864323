.container {
    width: 90%;
    height: 100%;
    gap: 1.5rem;
    margin: 2rem auto;
    padding-block: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.header {
    gap: 2rem;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    justify-content: space-between;
    border-bottom: 1px solid var(--sectionBorder);
}

.header h3 {
    font-size: 25px;
    font-weight: 500;
    line-height: 35px;
}

.main,
.content {
    gap: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.main h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: var(--grey3);
}

.main p,
.main span {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}

.main span {
    color: var(--grey3);
}

.content {
    gap: 10px;
}

.info {
    gap: 10%;
    display: flex;
    align-items: center;
    padding: 16px 0 10px;
    border-bottom: 1px solid var(--sectionBorder);
}

.footer {
    gap: 1.5rem;
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
}

.footer button {
    width: auto;
}

.footer button:nth-child(3) {
    display: none;
}

@media screen and (max-width: 767px) {
    .header h3 {
        font-size: 18px;
        line-height: 28px;
    }

    .main h4 {
        font-size: 16px;
        line-height: 26px;
    }

    .main p,
    .main span {
        font-size: 14px;
        line-height: 20px;
    }

    .footer button:nth-child(1),
    .footer button:nth-child(2) {
        display: none;
    }

    .footer button:nth-child(3) {
        display: flex;
    }
}